import { Tooltip, withStyles } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import CustomButton from "../../../components/custom-button";
import { formatNumberWithDollar } from "../../../utils/numberFormater";
import { primaryColor, secondaryColor } from "../../../scss/colors.scss";

const PrettoSlider = withStyles({
  root: {
    color: `${primaryColor}`,
    height: 8,
    marginBottom: "1rem",
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: `2px solid ${secondaryColor}`,
    marginTop: -7,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 4px)",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);

const SLIDER_MIN_VALUE = 50000;
const SLIDER_MAX_VALUE = 4000000;

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={formatNumberWithDollar(value)}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

function valuetext(value) {
  return `${value}`;
}
const SignUpSliderItem = ({
  setBuyingGoal,
  addingScenario,
  addingScenarioStatus,
}) => {
  const [sliderValue, setSliderValue] = useState(SLIDER_MAX_VALUE / 2);

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleAddBuyingGoal = () => {
    setBuyingGoal(sliderValue);
  };

  return (
    <div className="scenario-slider-item">
      <div className="signup-slider-header bc-title font-size-lg">
        {formatNumberWithDollar(sliderValue)}
        <CustomButton
          disabled={addingScenario}
          label="Add"
          onClick={handleAddBuyingGoal}
          isloading={addingScenario}
        />
      </div>
      <PrettoSlider
        disabled={addingScenario}
        ValueLabelComponent={ValueLabelComponent}
        min={SLIDER_MIN_VALUE}
        max={SLIDER_MAX_VALUE}
        value={sliderValue}
        onChange={handleChange}
        defaultValue={sliderValue}
        getAriaValueText={valuetext}
        step={1000}
        valueLabelDisplay="auto"
      />

      <div
        className={classNames({
          "text-danger": !addingScenarioStatus.status,
          "text-success": addingScenarioStatus.status,
        })}
      >
        {addingScenarioStatus.message}
      </div>
    </div>
  );
};

export default SignUpSliderItem;
